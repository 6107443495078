<template>
  <router-view />
</template>
<script>
import redirectMixin from '@/utils/mixins/Redirect';

export default {
  name: 'Signup',
  mixins: [redirectMixin],
  data() {
    return {
      collapseOpen: false,
      transitionName: '',
    };
  },
  computed: {
    isRoute() {
      return function(name) {
        return name === this.$route.name;
      };
    },
    planNotSelected() {
      return this.$store.getters['plans/getNotSelected'];
    },

    plans() {
      return this.$store.getters['plans/getPlans'];
    },
  },
  created() {
    this.init();
  },
  methods: {
    init () {
      this.$router.beforeEach((to, from, next) => {
        if (to.meta && from.meta) {
          let transitionName = to.meta.transitionName || from.meta.transitionName;
          if (transitionName === 'slide') {
            transitionName = to.meta.transitionOrder < from.meta.transitionOrder ? 'slide-right' : 'slide-left';
          }
          this.transitionName = transitionName || 'fade';
        }
        next();
      });
    },
    planFromUrl () {
      // grabs plan ID from url param, sets plan in state store
      if (this.$route.params.plan_id) {
        let selectedPlan = this.$store.getters['plans/getPlanByID'](
          this.$route.params.plan_id,
        );
        if (selectedPlan === undefined) {
          this.$router.push({name: 'SelectPlan'});
        }
        this.$store.commit('plans/setSelectedPlan', selectedPlan);
      }
    },
    goToPayment () {
      let plan = this.$store.getters['plans/getSelectedPlan'];
      if (this.$store.getters['plans/getSelectedPlan']) {
        this.$router.push({name: 'Payment', params: {plan_id: plan.id}});
      }
    },
  },
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
